import { SvgIcon } from "@mui/material";

const Reply = props => {
  return <SvgIcon viewBox="0 0 14 12" fill="none" {...props}>
      <path d="M5.29209 8.60364L5.29122 8.60286L1.46442 5.15874C1.40617 5.10531 1.375 5.03301 1.375 4.95976C1.375 4.88651 1.40617 4.81421 1.46442 4.76078L5.2899 1.31784C5.29006 1.3177 5.29022 1.31756 5.29037 1.31743C5.36802 1.24836 5.47921 1.22985 5.57855 1.27353C5.67245 1.31482 5.73438 1.40902 5.73438 1.51445V8.40507C5.73438 8.50599 5.67373 8.60292 5.57378 8.64812C5.47992 8.69056 5.3695 8.67364 5.29209 8.60364Z" fill="currentColor" stroke="currentColor" />

      <path opacity="0.4" d="M8.53125 6.37642H6.73438V3.5459H8.91406C10.9635 3.5459 12.625 5.20739 12.625 7.25684C12.625 9.45408 11.2021 10.5478 10.6364 10.897C10.6366 10.8969 10.6368 10.8967 10.6369 10.8965L10.6369 10.8965L10.6387 10.8949C10.9475 10.6058 11.3281 10.0583 11.3281 9.17329C11.3281 7.62908 10.0755 6.37642 8.53125 6.37642Z" fill="currentColor" stroke="currentColor" />
    </SvgIcon>;
};

export default Reply;