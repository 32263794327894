import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {routes} from "./routes/routes";
import {AuthProvider} from "./contexts/authContext";
import "./i18n";
import {createCustomTheme} from "./theme";
import useSettings from "hooks/useSettings";
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";

function App() {
    const router = createBrowserRouter(routes());
    const {settings} = useSettings();
    const theme = createCustomTheme(settings); // ROUTER CREATE

    return (<StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <CssBaseline/>
                    <RouterProvider router={router}/>
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>);
}

export default App;

