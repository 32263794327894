import { SvgIcon } from "@mui/material";

const Archive = props => {
  return <SvgIcon viewBox="0 0 16 15" fill="none" {...props}>
      <path d="M15.875 1.23438V2.71094C15.875 2.98164 15.6535 3.20312 15.3828 3.20312H0.617188C0.346115 3.20312 0.125 2.98164 0.125 2.71094V1.23438C0.125 0.6902 0.5652 0.25 1.10938 0.25H14.8906C15.4351 0.25 15.875 0.6902 15.875 1.23438Z" fill="currentColor" />

      <path opacity="0.4" d="M1.10938 12.5547C1.10938 13.3699 1.77044 14.0312 2.58594 14.0312H13.4141C14.2296 14.0312 14.8906 13.3702 14.8906 12.5547V3.20312H1.10938V12.5547ZM5.04688 5.66406C5.04688 5.39336 5.26836 5.17188 5.53906 5.17188H10.4609C10.7316 5.17188 10.9531 5.39336 10.9531 5.66406V6.64844C10.9531 6.91914 10.7316 7.14062 10.4609 7.14062H5.53906C5.26836 7.14062 5.04688 6.91914 5.04688 6.64844V5.66406Z" fill="currentColor" />
    </SvgIcon>;
};

export default Archive;