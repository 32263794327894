import DashboardPage from "../pages/dashboard/DashboardPage";
import {ForgetPasswordPageView, LoginPageView, Logout, RegisterPageView} from "../pages/login";
import {AuthGuard} from "../components/auth";
import ErrorPage from "../pages/other/ErrorPage";

export const routes = () => {
    return [ {
        path: "/login", element: <LoginPageView/>
    }, {
        path: "/logout", element: <AuthGuard><Logout/></AuthGuard>
    }, {
        path: "/", element: <AuthGuard><DashboardPage/></AuthGuard>
    }, {
        path: "/register", element: <RegisterPageView/>
    }, {
        path: "/forget-password", element: <ForgetPasswordPageView/>
    }, {
        path: "*", element: <ErrorPage/>
    }]

};