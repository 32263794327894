export const saveAuthToken = token => {
    localStorage.setItem("token", JSON.stringify(token))
}

export const getAuthToken = () => {
    const tokenString = localStorage.getItem("token")
    if (tokenString !== undefined) {
        const userToken = JSON.parse(tokenString)
        return {access: userToken?.access, refresh: userToken?.refresh}
    } else {
        return {access: null, refresh: null}
    }
}


export const deleteAuthToken = () => {
    localStorage.removeItem("token")
}

export default class BackendService {
    constructor() {
        this.host = process.env.REACT_APP_BACKEND_HOST;
    };

    getAuthorizationHeaders = () => {
        const {access} = getAuthToken();
        return {headers: {Authorization: `Bearer ${access}`}}
    };

    async obtainAuthToken(email, password, remember) {
        const requestPath = 'accounts/login/'
        const requestURL = `${this.host}/${requestPath}`
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: email, password: password, remember: remember})
        };

        return fetch(requestURL, requestOptions)
            .then(response => {
                return response.json()
            })
    }

    async refreshAuthToken() {
        const requestPath = "api/v1/token/refresh/"
        const requestURL = `${this.host}/${requestPath}`

        const {access, refresh} = getAuthToken();
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({access: access, refresh: refresh})
        };
        return fetch(requestURL, requestOptions)
            .then(response => response.json())
            .then(data => {
                saveAuthToken(data)
            })
            .catch(error => error)
    }

    async obtainMilkPriceStatistics() {
        const requestPath = "api/v1/milk_prices/"
        const requestURL = `${this.host}/${requestPath}`
        const authorizationHeaders = this.getAuthorizationHeaders()
        return fetch(requestURL, authorizationHeaders)
            .then(response => response.json())
            .catch(error => error)
    }

    async obtainUserHerds() {
        const requestPath = "api/v1/herd/"
        const requestURL = `${this.host}/${requestPath}`
        const authorizationHeaders = this.getAuthorizationHeaders()

        return fetch(requestURL, authorizationHeaders)
            .then(response => {
                return response.json()
            })
            .catch(error => error)
    }


    async obtainUserAnimals() {
        const requestPath = "api/v1/animal/"
        const requestURL = `${this.host}/${requestPath}`
        const authorizationHeaders = this.getAuthorizationHeaders()

        return fetch(requestURL, authorizationHeaders)
            .then(response => {
                return response.json()
            })
            .catch(error => error)
    }

}