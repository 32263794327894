import {createContext} from "react";
import {THEMES} from "utils/constants";
import useLocalStorage from "hooks/useLocalStorage";

const initialSettings = {
    theme: THEMES.LIGHT,
    direction: 'ltr',
    responsiveFontSizes: true
};

export const SettingsContext = createContext({
    settings: initialSettings,
    saveSettings: arg => {
    }
});

const SettingsProvider = ({children}) => {
    const storage = useLocalStorage("settings", initialSettings);
    const {
        data: settings,
        storeData: setStoreSettings
    } = storage;

    const saveSettings = updateSettings => setStoreSettings(updateSettings);

    return <SettingsContext.Provider value={{
        settings,
        saveSettings
    }}>
        {children}
    </SettingsContext.Provider>;
};

export default SettingsProvider;