// MUI COMPONENTS
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import {H1, Paragraph} from "components/typography";

import useNavigate from "hooks/useNavigate";

const ErrorPage = () => {
    const navigate = useNavigate();
    return <Container>
        <Box textAlign="center" py={6}>
            <H1 fontSize={{sm: 52, xs: 42}}>გვერდი არ არსებობს!</H1>
            <Paragraph mt={1} fontSize={18} color="text.secondary">
                სამწუხაროდ, ჩვენ ვერ შევძელით მოცემული გვერდის მოძებნა.
            </Paragraph>

            <Box py={10} maxWidth={600} margin="auto">
                <img src="/static/pages/error.svg" alt="error" width="100%"/>
            </Box>

            <Button onClick={() => navigate("/app")}>საწყის გვერდზე დაბრუნება</Button>
        </Box>
    </Container>;
};

export default ErrorPage;