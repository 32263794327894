import {Fragment, useContext, useState} from "react";
import {Box, IconButton} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import {SettingsContext} from "contexts/settingsContext";
import ThemeIcon from "icons/ThemeIcon";
import Search from "icons/duotone/Search";

import SearchBar from "../layout-parts/SearchBar";
import ProfilePopover from "../layout-parts/popovers/ProfilePopover";
import ServicePopover from "../layout-parts/popovers/ServicePopover";
import LanguagePopover from "../layout-parts/popovers/LanguagePopover";
import NotificationsPopover from "../layout-parts/popovers/NotificationsPopover";
import {DashboardHeaderRoot, StyledToolBar} from "../layout-parts/styles/header";

const DashboardHeader = () => {
    const [openSearchBar, setSearchBar] = useState(false);
    const {settings, saveSettings} = useContext(SettingsContext);


    const handleChangeTheme = value => {
        saveSettings({
            ...settings,
            theme: value
        });
    };

    return <DashboardHeaderRoot position="sticky">
        <StyledToolBar>
            <ClickAwayListener onClickAway={() => setSearchBar(false)}>
                <Box>
                    {!openSearchBar ?
                        <IconButton onClick={() => setSearchBar(true)}>
                            <Search sx={{color: "grey.400", fontSize: 18}}/>
                        </IconButton> : null}
                    <SearchBar open={openSearchBar} handleClose={() => setSearchBar(false)}/>
                </Box>
            </ClickAwayListener>

            <Box flexGrow={1} ml={1}/>
            <IconButton onClick={() => {
                handleChangeTheme(settings.theme === "light" ? "dark" : "light");
            }}>
                <ThemeIcon/>
            </IconButton>

            <Fragment>
                <LanguagePopover/>
                <NotificationsPopover/>
                <ServicePopover/>
            </Fragment>

            <ProfilePopover/>
        </StyledToolBar>
    </DashboardHeaderRoot>;
};

export default DashboardHeader;