import {createContext, useReducer} from "react";

import BackendService from "../utils/backendService";
import {Navigate} from "react-router-dom";

const USER_DETAILS_KEY = 'userDetails'
const initialAuthState = {
    user: {
        id: null, email: null, avatar: '#', firstName: null, lastName: null, access: null, refresh: null
    }, isAuthenticated: false
};


const dumpUserDetailsToDisk = (user) => {
    localStorage.setItem(USER_DETAILS_KEY, JSON.stringify(user))
}

const loadUserDetailsFromDisk = () => {
    const detailsString = localStorage.getItem(USER_DETAILS_KEY)

    if (detailsString !== undefined) {
        return JSON.parse(detailsString)
    }

    return null
}


const createUserWithEmail = () => {
    console.log('createUserWithEmail')
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN_WITH_EMAIL':
            return {
                user: action.payload, isAuthenticated: true
            };
        case 'LOGOUT':
            return {...initialAuthState}
        default:
            return state;
    }
};


export const AuthContext = createContext({
    ...initialAuthState,
    method: "BACKEND",
    logout: () => {
    },
    signInWithEmail: () => {
    },
    createUserWithEmail
});


export const AuthProvider = ({children}) => {
    const userDetails = loadUserDetailsFromDisk()
    let authState = {...initialAuthState}
    if (userDetails) {
        authState.user = userDetails
        authState.isAuthenticated = true
    }

    const [state, dispatch] = useReducer(reducer, authState);

    const signInWithEmail = (email, password, remember) => {
        const backendApi = new BackendService()
        return backendApi.obtainAuthToken(email, password, remember)
            .then(response => {
                if (!response.access) {
                    return {status: false, error: response.non_field_errors[0]}
                }

                const user = {
                    id: response.user.pk,
                    email: response.user.email,
                    firstName: response.user.first_name,
                    lastName: response.user.last_name,
                    avatar: '#',
                    access: response.access,
                    refresh: response.refresh,
                }
                dumpUserDetailsToDisk(user)

                dispatch({
                    type: 'LOGIN_WITH_EMAIL', payload: user
                })

                return {status: true, error: null}
            })

    };

    const logout = () => {
        localStorage.removeItem(USER_DETAILS_KEY)
        dispatch({
            type: 'LOGOUT'
        })
        return <Navigate to='/login'/>
    }

    return <AuthContext.Provider value={{
        ...state, logout, signInWithEmail, method: "BACKEND", createUserWithEmail
    }}>
        {children}
    </AuthContext.Provider>;
};