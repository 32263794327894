import AdminEcommerce from "./AdminEcommerce";
import Dashboard from "./Dashboard";
import Ecommerce from "./Ecommerce";
import ElementHub from "./ElementHub";
import Logout from "./Logout";
import ProjectChart from "./ProjectChart";
import Settings from "./Settings";
import UserProfile from "./UserProfile";
import TableList from "./DataTable";
import DataTable from "./DataTable";
import Accounts from "./Accounts";
import Calender from "./Calender";
import Chat from "./Chat";
import Invoice from "./Invoice";
import TodoList from "./TodoList";
import Session from "./Session";
import Pages from "./Pages";
import Pricing from "./Pricing";
import ArrowLeftToLine from "./ArrowLeftToLine";
import MessagesDollar from "./MessagesDollar";
import BadgeDollar from "./BadgeDollar";
import PersonChalkboard from "./PersonChalkboard";
import CommentsQuestionCheck from "./CommentsQuestionCheck";
import PersonCircleCheck from "./PersonCircleCheck";
import RectangleCirclePlus from "./RectangleCirclePlus";
import DiagramProject from "./DiagramProject";
import LayerGroup from "./LayerGroup";
import FileCircleQuestion from "./FileCircleQuestion";
import UserList from "./UserList";
import Search from "./Search";
import Mail from "./Mail";
import Inbox from "./Inbox";
import Send from "./Send";
import Edit from "./Edit";
import StartHalf from "./StarHalf";
import Report from "./Report";
import Trash from "./Trash";
import Reload from "./Reload";
import Archive from "./Archive";
import ReadMail from "./ReadMail";
import Reply from "./Reply";
import Forward from "./Forward";
import Folder from "./Folder";
import Apps from "./Apps";

const icons = {
    AdminEcommerce,
    ArrowLeftToLine,
    CommentsQuestionCheck,
    Dashboard,
    DiagramProject,
    Ecommerce,
    ElementHub,
    FileCircleQuestion,
    Logout,
    ProjectChart,
    Settings,
    UserProfile,
    UserList,
    TableList,
    Accounts,
    Calender,
    Chat,
    Invoice,
    TodoList,
    Session,
    Pages,
    Pricing,
    DataTable,
    LayerGroup,
    MessagesDollar,
    BadgeDollar,
    RectangleCirclePlus,
    PersonChalkboard,
    PersonCircleCheck,
    Search,
    Mail,
    Inbox,
    Send,
    Edit,
    StartHalf,
    Report,
    Trash,
    Reload,
    Archive,
    ReadMail,
    Reply,
    Forward,
    Folder,
    Apps
}
export default icons;