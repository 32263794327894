import React, {useEffect} from "react";

import {Navigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
    const {logout} = useAuth();
    useEffect(() => {
        const logoutHandler = async () => {
            await logout()
        }

        logoutHandler()
    }, [logout]);

    return <Navigate to='/login'/>
}

export default Logout