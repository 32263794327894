import {Box, Grid} from "@mui/material"; // CUSTOM PAGE SECTION COMPONENTS
import Footer from "./_common/Footer";
import TotalItems from "./sections/TotalItems";
import TotalOrder from "./sections/TotalOrder";
import YearlySales from "./sections/YearlySales";
import AllChannels from "./sections/AllChannels";
import CheckUpdate from "./sections/CheckUpdate";
import ChartFilters from "./sections/ChartFilters";
import AllCampaigns from "./sections/AllCampaigns";
import YearlyRevenue from "./sections/YearlyRevenue";
import YoutubeCampaign from "./sections/YoutubeCampaign";

const DashboardPageSections = () => {
    return <Box pt={2} pb={4}>
        <Grid container spacing={3}>
            {
                /* TOTAL ITEMS CARD */
            }
            <Grid item lg={3} sm={6} xs={12}>
                <TotalItems/>
            </Grid>

            {
                /* YEARLY SALES CARD */
            }
            <Grid item lg={3} sm={6} xs={12}>
                <YearlySales/>
            </Grid>

            {
                /* TOTAL ORDER CARD */
            }
            <Grid item lg={3} sm={6} xs={12}>
                <TotalOrder/>
            </Grid>

            {
                /* YEARLY REVENUE CARD */
            }
            <Grid item lg={3} sm={6} xs={12}>
                <YearlyRevenue/>
            </Grid>

            {
                /* CHART FILTERS CARD */
            }
            <Grid item md={8} xs={12}>
                <ChartFilters/>
            </Grid>

            {
                /* ALL CHANNELS CARD  */
            }
            <Grid item md={4} xs={12}>
                <AllChannels/>
            </Grid>

            {
                /* YOUTUBE CAMPAIGN CHART CARD */
            }
            <Grid item md={4} xs={12}>
                <YoutubeCampaign/>
            </Grid>

            {
                /* ALL CAMPAIGNS LIST CARD */
            }
            <Grid item md={8} xs={12}>
                <AllCampaigns/>
            </Grid>

            {
                /* CHECK UPDATE CARD */
            }
            <Grid item md={4} xs={12}>
                <CheckUpdate/>
            </Grid>

            {
                /* SHIPMENT HISTORY CARD */
            }


            {
                /* FOOTER CARD */
            }
            <Grid item xs={12}>
                <Footer/>
            </Grid>
        </Grid>
    </Box>;
};

export default DashboardPageSections;